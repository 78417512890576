/* eslint-disable class-methods-use-this */
import AuthenticatedHTTPWrapper from '../utils/AuthenticatedHTTPWrapper';
import HTTPWrapper from '../utils/HTTPWrapper';

class UserAPI {
  signIn = (config) =>
    HTTPWrapper.post({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/schools_portal_users/sign_in.json`,
      config
    });

  newRegistration = () =>
    HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/registration/new.json`
    });

  signUp = (config) =>
    HTTPWrapper.post({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/registration.json`,
      config
    });

  signOut = (config) =>
    AuthenticatedHTTPWrapper.delete({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/schools_portal_users/sign_out.json`,
      config
    });

  setPassword = (config) =>
    HTTPWrapper.post({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/password.json`,
      config
    });

  updatePassword = (data) =>
    AuthenticatedHTTPWrapper.patch({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/password.json`,
      data
    });

  update = (schoolContactId, data) =>
    AuthenticatedHTTPWrapper.patch({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/school_contacts/${schoolContactId}.json`,
      data
    });

  fetch = () =>
    AuthenticatedHTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/current_user.json`
    });

  myRoleChangeRequests = () =>
    AuthenticatedHTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/role_change_requests.json`
    });

  requestRoleChange = (config) =>
    AuthenticatedHTTPWrapper.post({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/role_change_requests.json`,
      config
    });

  cancelRoleChangeRequest = (id) =>
    AuthenticatedHTTPWrapper.delete({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal/role_change_requests/${id}.json`
    });

  unlockAccount = (token) =>
    HTTPWrapper.get({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal_users/unlock?unlock_token=${token}`
    });

  resendUnlockInstructions = (config) =>
    HTTPWrapper.post({
      url: `${process.env.REACT_APP_ABBEY_ROAD_URL}/api/schools_portal_users/unlock`,
      config
    });
}

export default new UserAPI();

import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import SignUpForm from '../components/SignUpForm/SignUpForm';
import paths from '../consts/paths';
import AuthContainer from '../components/AuthContainer';
import BackToPathLink from '../components/BackToPathLink/BackToPathLink';

export default function SignUpPage() {
  const [signUpValues, setSignUpValues] = useState({
    email: '',
    title: '',
    firstName: '',
    lastName: '',
    role: '',
    school: '',
    optedInForMarketing: false
  });

  const [animationSlidesIn, setAnimationSlidesIn] = useState(true);

  const [slideInRef] = useOutletContext();

  const navigate = useNavigate();

  const onExited = () => navigate(paths.LOG_IN);

  const handleNavigatingBack = () => {
    setAnimationSlidesIn(false);
  };

  const backToLogin = () => (
    <BackToPathLink text="Return to sign in" onNavigatingBack={handleNavigatingBack} />
  );

  const signUpHeading = () => (
    <Typography variant="h1" mb={16}>
      Create an account
    </Typography>
  );

  const signUpDescription = () => (
    <Typography variant="body">
      Create an account to access <em>Feel Good Friday</em>, find out more about Rocksteady
      peripatetic lessons, and if you have in-school lessons, access your school dashboard.
    </Typography>
  );

  const signUpContent = () => (
    <Box display="flex" flexDirection="column">
      {backToLogin()}
      {signUpHeading()}
      {signUpDescription()}
      <SignUpForm signUpValues={signUpValues} setSignUpValues={setSignUpValues} />
    </Box>
  );

  return (
    <AuthContainer
      animationSlidesIn={animationSlidesIn}
      containerRef={slideInRef}
      onExitedCallback={onExited}>
      {signUpContent()}
    </AuthContainer>
  );
}
